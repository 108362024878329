.v-skeleton-loader {
  align-items: center;
  background: rgb(var(--v-theme-surface));
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  vertical-align: top;
}
.v-skeleton-loader__actions {
  justify-content: end;
}
.v-skeleton-loader .v-skeleton-loader__ossein {
  height: 100%;
}
.v-skeleton-loader .v-skeleton-loader__avatar,
.v-skeleton-loader .v-skeleton-loader__button,
.v-skeleton-loader .v-skeleton-loader__chip,
.v-skeleton-loader .v-skeleton-loader__divider,
.v-skeleton-loader .v-skeleton-loader__heading,
.v-skeleton-loader .v-skeleton-loader__image,
.v-skeleton-loader .v-skeleton-loader__ossein,
.v-skeleton-loader .v-skeleton-loader__text {
  background: rgba(var(--v-theme-on-surface), var(--v-border-opacity));
}
.v-skeleton-loader .v-skeleton-loader__list-item,
.v-skeleton-loader .v-skeleton-loader__list-item-avatar,
.v-skeleton-loader .v-skeleton-loader__list-item-text,
.v-skeleton-loader .v-skeleton-loader__list-item-two-line,
.v-skeleton-loader .v-skeleton-loader__list-item-avatar-two-line,
.v-skeleton-loader .v-skeleton-loader__list-item-three-line,
.v-skeleton-loader .v-skeleton-loader__list-item-avatar-three-line {
  border-radius: 4px;
}
.v-skeleton-loader__bone {
  align-items: center;
  border-radius: inherit;
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;
}
.v-skeleton-loader__bone::after {
  animation: loading 1.5s infinite;
  background: linear-gradient(90deg, rgba(var(--v-theme-surface), 0), rgba(var(--v-theme-surface), 0.3), rgba(var(--v-theme-surface), 0));
  transform: translateX(-100%);
  z-index: 1;
}
.v-skeleton-loader__bone::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.v-skeleton-loader__avatar {
  border-radius: 50%;
  flex: 0 1 auto;
  margin: 8px 16px;
  max-height: 48px;
  min-height: 48px;
  height: 48px;
  max-width: 48px;
  min-width: 48px;
  width: 48px;
}
.v-skeleton-loader__avatar + .v-skeleton-loader__bone {
  flex: 1 1 auto;
  margin-inline-start: 0;
}
.v-skeleton-loader__avatar + .v-skeleton-loader__sentences > .v-skeleton-loader__text,
.v-skeleton-loader__avatar + .v-skeleton-loader__paragraph > .v-skeleton-loader__text {
  margin-inline-start: 0;
}
.v-skeleton-loader__button {
  border-radius: 4px;
  height: 36px;
  margin: 16px;
  max-width: 64px;
}
.v-skeleton-loader__button + .v-skeleton-loader__bone {
  flex: 1 1 auto;
  margin-inline-start: 0;
}
.v-skeleton-loader__button + .v-skeleton-loader__sentences > .v-skeleton-loader__text,
.v-skeleton-loader__button + .v-skeleton-loader__paragraph > .v-skeleton-loader__text {
  margin-inline-start: 0;
}
.v-skeleton-loader__chip {
  border-radius: 16px;
  margin: 16px;
  height: 32px;
  max-width: 96px;
}
.v-skeleton-loader__chip + .v-skeleton-loader__bone {
  flex: 1 1 auto;
  margin-inline-start: 0;
}
.v-skeleton-loader__chip + .v-skeleton-loader__sentences > .v-skeleton-loader__text,
.v-skeleton-loader__chip + .v-skeleton-loader__paragraph > .v-skeleton-loader__text {
  margin-inline-start: 0;
}
.v-skeleton-loader__date-picker {
  border-radius: inherit;
}
.v-skeleton-loader__date-picker .v-skeleton-loader__list-item:first-child .v-skeleton-loader__text {
  max-width: 88px;
  width: 20%;
}
.v-skeleton-loader__date-picker .v-skeleton-loader__heading {
  max-width: 256px;
  width: 40%;
}
.v-skeleton-loader__date-picker-days {
  flex-wrap: wrap;
  margin: 16px;
}
.v-skeleton-loader__date-picker-days .v-skeleton-loader__avatar {
  border-radius: 4px;
  margin: 4px;
  max-width: 100%;
}
.v-skeleton-loader__date-picker-options {
  flex-wrap: nowrap;
}
.v-skeleton-loader__date-picker-options .v-skeleton-loader__text {
  flex: 1 1 auto;
}
.v-skeleton-loader__divider {
  border-radius: 1px;
  height: 2px;
}
.v-skeleton-loader__heading {
  border-radius: 12px;
  margin: 16px;
  height: 24px;
}
.v-skeleton-loader__heading + .v-skeleton-loader__subtitle {
  margin-top: -16px;
}
.v-skeleton-loader__image {
  height: 150px;
  border-radius: 0;
}
.v-skeleton-loader__card .v-skeleton-loader__image {
  border-radius: 0;
}
.v-skeleton-loader__list-item {
  margin: 16px;
}
.v-skeleton-loader__list-item .v-skeleton-loader__text {
  margin: 0;
}
.v-skeleton-loader__table-thead {
  justify-content: space-between;
}
.v-skeleton-loader__table-thead .v-skeleton-loader__heading {
  margin-top: 16px;
  max-width: 16px;
}
.v-skeleton-loader__table-tfoot {
  flex-wrap: nowrap;
}
.v-skeleton-loader__table-tfoot > .v-skeleton-loader__text.v-skeleton-loader__bone {
  margin-top: 16px;
}
.v-skeleton-loader__table-row {
  align-items: baseline;
  margin: 0 8px;
  justify-content: space-evenly;
  flex-wrap: nowrap;
}
.v-skeleton-loader__table-row > .v-skeleton-loader__text.v-skeleton-loader__bone {
  margin-inline: 8px;
}
.v-skeleton-loader__table-row + .v-skeleton-loader__divider {
  margin: 0 16px;
}
.v-skeleton-loader__table-cell {
  align-items: center;
  display: flex;
  height: 48px;
  width: 88px;
}
.v-skeleton-loader__table-cell .v-skeleton-loader__text {
  margin-bottom: 0;
}
.v-skeleton-loader__subtitle {
  max-width: 70%;
}
.v-skeleton-loader__subtitle > .v-skeleton-loader__text {
  height: 16px;
  border-radius: 8px;
}
.v-skeleton-loader__text {
  border-radius: 6px;
  margin: 16px;
  height: 12px;
}
.v-skeleton-loader__text + .v-skeleton-loader__text {
  margin-top: -8px;
  max-width: 50%;
}
.v-skeleton-loader__text + .v-skeleton-loader__text + .v-skeleton-loader__text {
  max-width: 70%;
}
.v-skeleton-loader--boilerplate .v-skeleton-loader__bone:after {
  display: none;
}
.v-skeleton-loader--is-loading {
  overflow: hidden;
}
.v-skeleton-loader--tile {
  border-radius: 0;
}
.v-skeleton-loader--tile .v-skeleton-loader__bone {
  border-radius: 0;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
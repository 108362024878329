.v-list {
  overflow: auto;
  padding: 8px 0;
  position: relative;
  outline: none;
}
.v-list {
  border-color: rgba(var(--v-border-color), var(--v-border-opacity));
  border-style: solid;
  border-width: 0;
}
.v-list--border {
  border-width: thin;
  box-shadow: none;
}
.v-list {
  box-shadow: 0px 0px 0px 0px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 0px 0px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 0px 0px 0px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));
}
.v-list {
  border-radius: 0;
}
.v-list {
  background: rgba(var(--v-theme-surface));
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
}
.v-list--disabled {
  pointer-events: none;
  user-select: none;
}
.v-list--nav {
  padding-inline: 8px;
}
.v-list--rounded {
  border-radius: 4px;
}
.v-list--subheader {
  padding-top: 0;
}

.v-list-img {
  border-radius: inherit;
  display: flex;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.v-list-subheader {
  align-items: center;
  background: inherit;
  color: rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity));
  display: flex;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  padding-inline-end: 16px;
  min-height: 40px;
  transition: 0.2s min-height cubic-bezier(0.4, 0, 0.2, 1);
}
.v-list-subheader__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.v-list--density-default .v-list-subheader {
  min-height: 40px;
  padding-inline-start: calc(16px + var(--indent-padding)) !important;
}

.v-list--density-comfortable .v-list-subheader {
  min-height: 36px;
  padding-inline-start: calc(16px + var(--indent-padding)) !important;
}

.v-list--density-compact .v-list-subheader {
  min-height: 32px;
  padding-inline-start: calc(16px + var(--indent-padding)) !important;
}

.v-list-subheader--inset {
  --indent-padding: 56px;
}
.v-list--nav .v-list-subheader {
  font-size: 0.75rem;
}
.v-list-subheader--sticky {
  background: inherit;
  left: 0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.v-list__overlay {
  background-color: currentColor;
  border-radius: inherit;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s ease-in-out;
}
.v-autocomplete .v-field .v-text-field__prefix,
.v-autocomplete .v-field .v-text-field__suffix,
.v-autocomplete .v-field .v-field__input, .v-autocomplete .v-field.v-field {
  cursor: text;
}
.v-autocomplete .v-field .v-field__input > input {
  flex: 1 1;
}
.v-autocomplete .v-field input {
  min-width: 64px;
}
.v-autocomplete .v-field:not(.v-field--focused) input {
  min-width: 0;
}
.v-autocomplete .v-field--dirty .v-autocomplete__selection {
  margin-inline-end: 2px;
}
.v-autocomplete .v-autocomplete__selection-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-autocomplete__content {
  overflow: hidden;
}
.v-autocomplete__content {
  box-shadow: 0px 2px 4px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 4px 5px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 10px 0px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));
}
.v-autocomplete__content {
  border-radius: 4px;
}
.v-autocomplete__mask {
  background: rgb(var(--v-theme-surface-light));
}
.v-autocomplete__selection {
  display: inline-flex;
  align-items: center;
  height: 1.5rem;
  letter-spacing: inherit;
  line-height: inherit;
  max-width: calc(100% - 2px - 2px);
}
.v-autocomplete__selection:first-child {
  margin-inline-start: 0;
}
.v-autocomplete--chips.v-input--density-compact .v-field--variant-solo .v-label.v-field-label--floating,
.v-autocomplete--chips.v-input--density-compact .v-field--variant-solo-inverted .v-label.v-field-label--floating,
.v-autocomplete--chips.v-input--density-compact .v-field--variant-filled .v-label.v-field-label--floating,
.v-autocomplete--chips.v-input--density-compact .v-field--variant-solo-filled .v-label.v-field-label--floating {
  top: 0px;
}
.v-autocomplete--selecting-index .v-autocomplete__selection {
  opacity: var(--v-medium-emphasis-opacity);
}
.v-autocomplete--selecting-index .v-autocomplete__selection--selected {
  opacity: 1;
}
.v-autocomplete--selecting-index .v-field__input > input {
  caret-color: transparent;
}
.v-autocomplete--single:not(.v-autocomplete--selection-slot).v-text-field input {
  flex: 1 1;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  padding-inline: inherit;
}
.v-autocomplete--single:not(.v-autocomplete--selection-slot) .v-field--active input {
  transition: none;
}
.v-autocomplete--single:not(.v-autocomplete--selection-slot) .v-field--dirty:not(.v-field--focused) input {
  opacity: 0;
}
.v-autocomplete--single:not(.v-autocomplete--selection-slot) .v-field--focused .v-autocomplete__selection {
  opacity: 0;
}
.v-autocomplete__menu-icon {
  margin-inline-start: 4px;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.v-autocomplete--active-menu .v-autocomplete__menu-icon {
  opacity: var(--v-high-emphasis-opacity);
  transform: rotate(180deg);
}
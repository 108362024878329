.v-divider {
  display: block;
  flex: 1 1 100%;
  height: 0px;
  max-height: 0px;
  opacity: var(--v-border-opacity);
  transition: inherit;
}
.v-divider {
  border-style: solid;
  border-width: thin 0 0 0;
}
.v-divider--vertical {
  align-self: stretch;
  border-width: 0 thin 0 0;
  display: inline-flex;
  height: auto;
  margin-left: -1px;
  max-height: 100%;
  max-width: 0px;
  vertical-align: text-bottom;
  width: 0px;
}
.v-divider--inset:not(.v-divider--vertical) {
  max-width: calc(100% - 72px);
  margin-inline-start: 72px;
}
.v-divider--inset.v-divider--vertical {
  margin-bottom: 8px;
  margin-top: 8px;
  max-height: calc(100% - 16px);
}

.v-divider__content {
  padding: 0 16px;
  text-wrap: nowrap;
}
.v-divider__wrapper--vertical .v-divider__content {
  padding: 4px 0;
}

.v-divider__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-divider__wrapper--vertical {
  flex-direction: column;
  height: 100%;
}
.v-divider__wrapper--vertical .v-divider {
  margin: 0 auto;
}
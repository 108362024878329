.v-snackbar {
  justify-content: center;
  z-index: 10000;
  margin: 8px;
  margin-inline-end: calc(8px + var(--v-scrollbar-offset));
  padding: var(--v-layout-top) var(--v-layout-right) var(--v-layout-bottom) var(--v-layout-left);
}
.v-snackbar:not(.v-snackbar--center):not(.v-snackbar--top) {
  align-items: flex-end;
}
.v-snackbar__wrapper {
  align-items: center;
  display: flex;
  max-width: 672px;
  min-height: 48px;
  min-width: 344px;
  overflow: hidden;
  padding: 0;
}
.v-snackbar__wrapper {
  border-radius: 4px;
}
.v-snackbar--variant-plain, .v-snackbar--variant-outlined, .v-snackbar--variant-text, .v-snackbar--variant-tonal {
  background: transparent;
  color: inherit;
}
.v-snackbar--variant-plain {
  opacity: 0.62;
}
.v-snackbar--variant-plain:focus, .v-snackbar--variant-plain:hover {
  opacity: 1;
}
.v-snackbar--variant-plain .v-snackbar__overlay {
  display: none;
}
.v-snackbar--variant-elevated, .v-snackbar--variant-flat {
  background: rgb(var(--v-theme-surface-variant));
  color: rgb(var(--v-theme-on-surface-variant));
}
.v-snackbar--variant-elevated {
  box-shadow: 0px 3px 5px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 6px 10px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 18px 0px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));
}
.v-snackbar--variant-flat {
  box-shadow: 0px 0px 0px 0px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 0px 0px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 0px 0px 0px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));
}
.v-snackbar--variant-outlined {
  border: thin solid currentColor;
}
.v-snackbar--variant-text .v-snackbar__overlay {
  background: currentColor;
}
.v-snackbar--variant-tonal .v-snackbar__underlay {
  background: currentColor;
  opacity: var(--v-activated-opacity);
  border-radius: inherit;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
.v-snackbar .v-snackbar__underlay {
  position: absolute;
}

.v-snackbar__content {
  flex-grow: 1;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.425;
  margin-right: auto;
  padding: 14px 16px;
  text-align: initial;
}
.v-snackbar__actions {
  align-items: center;
  align-self: center;
  display: flex;
  margin-inline-end: 8px;
}
.v-snackbar__actions > .v-btn {
  padding: 0 8px;
  min-width: auto;
}
.v-snackbar__timer {
  width: 100%;
  position: absolute;
  top: 0;
}
.v-snackbar__timer .v-progress-linear {
  transition: 0.2s linear;
}
.v-snackbar--absolute {
  position: absolute;
  z-index: 1;
}
.v-snackbar--multi-line .v-snackbar__wrapper {
  min-height: 68px;
}
.v-snackbar--vertical .v-snackbar__wrapper {
  flex-direction: column;
}
.v-snackbar--vertical .v-snackbar__wrapper .v-snackbar__actions {
  align-self: flex-end;
  margin-bottom: 8px;
}
.v-snackbar--center {
  align-items: center;
  justify-content: center;
}
.v-snackbar--top {
  align-items: flex-start;
}
.v-snackbar--bottom {
  align-items: flex-end;
}
.v-snackbar--left, .v-snackbar--start {
  justify-content: flex-start;
}
.v-snackbar--right, .v-snackbar--end {
  justify-content: flex-end;
}

.v-snackbar-transition-enter-active, .v-snackbar-transition-leave-active {
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.v-snackbar-transition-enter-active {
  transition-property: opacity, transform;
}
.v-snackbar-transition-enter-from {
  opacity: 0;
  transform: scale(0.8);
}
.v-snackbar-transition-leave-active {
  transition-property: opacity;
}
.v-snackbar-transition-leave-to {
  opacity: 0;
}